import moment from 'moment';
import { i18n } from '@/i18n';

const formatDate = date =>
  moment(date)
    .locale(i18n.language)
    .format('LL');

const formatTime = date => moment(date).format('HH:mm');

const formatMinutes = date => moment(date).format('mm:ss');

const getDifferenceBySeconds = (date1, date2) => moment(date2).diff(moment(date1), 'seconds');

const pad = (num, size) => `000${num}`.slice(size * -1);

const convertSecondsToMInutes = timeInSeconds => {
  const time = parseFloat(timeInSeconds).toFixed(3);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

const convertSecondsToInversePercentage = (maxValue, seconds) => {
  const value = (100 * seconds) / maxValue;
  return 100 - value;
};

export {
  convertSecondsToInversePercentage,
  convertSecondsToMInutes,
  formatDate,
  formatMinutes,
  formatTime,
  getDifferenceBySeconds,
};
