const internalApiPaths = {
  ACCOUNT_ME: '/api/account',
  BITSA_POINTS: '/api/bitsa-points',
  CARDS_ALL: '/api/cards',
  CARD_DETAILS: '/api/cards/details',
  CARD_TRANSACTIONS: '/api/cards/transactions',
  CHANGE_ALIAS: '/api/cards/change-alias',
  CHECK_PAYMENT_STATUS: '/api/payments/status',
  CHECK_SEND_TO_PHONE: '/api/cards/send-money?route=checksend-tophone',
  CHECK_SEND_TO_CARD: '/api/cards/send-money?route=checksend-tocard',
  CHECK_TOKEN: '/api/websecurity/check-token',
  CHECK_VOUCHER: '/api/cards/voucher/check-voucher',
  FETCH_PLANS: '/api/configuration/levels',
  GET_CRYPTO_CONFIGURATION: '/api/cards/deposits?route=get-crypto-configuration',
  GET_IBAN_DEPOSIT_CONFIGURATION: '/api/cards/deposits?route=get-iban-deposit-configuration',
  GET_TPV_RECHARGE__CONFIGURATION: '/api/cards/deposits?route=get-tpv-recharge-configuration',
  LEVEL_CHANGE_PERIOD: '/api/cards/bitsa-levels/change-period',
  LEVEL_DOWNGRADE_PERFORM: '/api/cards/bitsa-levels/downgrade/perform',
  LEVEL_DOWNGRADE_VALIDATE: '/api/cards/bitsa-levels/downgrade/validate',
  LEVEL_UPGRADE_PERFORM: '/api/cards/bitsa-levels/upgrade/perform',
  LEVEL_UPGRADE_VALIDATE: '/api/cards/bitsa-levels/upgrade/validate',
  LEVELS_CONFIG: '/api/cards/bitsa-levels/configuration',
  LEVELS_VOID_CHANGE: '/api/cards/bitsa-levels/void-change',
  LOCK_CARD: '/api/cards/lock?route=lock',
  PERFORM_CRYPTO_RECHARGE: '/api/cards/deposits?route=perform-crypto-recharge',
  PERFORM_TPV_RECHARGE: '/api/cards/deposits?route=perform-tpv-recharge',
  REDEEM_VOUCHER: '/api/cards/voucher/redeem-voucher',
  REQUEST_ACCESS: '/api/websecurity/request-access',
  SEND_TO_CARD: '/api/cards/send-money?route=send-tocard',
  SEND_TO_IBAN: '/api/cards/manage?route=send-to-iban',
  SEND_TO_PHONE: '/api/cards/send-money?route=send-tophone',
  SET_CONFIGURATION: '/api/account/configuration',
  UNLOCK_CARD: '/api/cards/lock?route=unlock',
  VALIDATE_IBAN_TRANSFER: '/api/cards/manage?route=validate-iban-transfer',
};

const externalApiPaths = {
  ACCOUNT_ME: '/account/me',
  BITSA_POINTS: '/bitsapoints/GetPOIs',
  CARDS_ALL: '/cards/bitsa/multicard/cards?showAll=false&version=6',
  CARD_DETAILS: '/cards/bitsa/multicard/details',
  CARD_TRANSACTIONS: '/cards/transactions',
  CHANGE_ALIAS: '/cards/change-alias',
  CHECK_PAYMENT_STATUS: '/payments/status',
  CHECK_SEND_TO_PHONE: '/cards/bitsa/send-money/checksend-tophone',
  CHECK_SEND_TO_CARD: '/cards/bitsa/send-money/checksend-tocard',
  CHECK_TOKEN: '/websecurity/check-token',
  CHECK_VOUCHER: '/cards/bitsa/voucher/check-voucher',
  FETCH_PLANS: '/configurations/bitsa/levels/config',
  GET_CRYPTO_CONFIGURATION: '/cards/bitsa/deposits/get-configuration?currencyVersion=6',
  GET_IBAN_DEPOSIT_CONFIGURATION: '/cards/bitsa/deposits/get-iban-deposit-configuration/',
  GET_TPV_RECHARGE__CONFIGURATION: '/cards/bitsa/deposits/get-tpv-recharge-configuration?cardId=',
  LEVEL_CHANGE_PERIOD: '/cards/bitsa-levels/change-period',
  LEVEL_DOWNGRADE_PERFORM: '/cards/bitsa-levels/downgrade/perform',
  LEVEL_DOWNGRADE_VALIDATE: '/cards/bitsa-levels/downgrade/validate',
  LEVEL_UPGRADE_PERFORM: '/cards/bitsa-levels/upgrade/perform',
  LEVEL_UPGRADE_VALIDATE: '/cards/bitsa-levels/upgrade/validate',
  LEVELS_CONFIG: '/cards/bitsa-levels/configuration',
  LEVELS_VOID_CHANGE: '/cards/bitsa-levels/void-change',
  LOCK_CARD: '/cards/lock-card',
  PERFORM_CRYPTO_RECHARGE: '/cards/bitsa/deposits/perform-recharge',
  PERFORM_TPV_RECHARGE: '/cards/bitsa/deposits/perform-tpv-recharge',
  REDEEM_VOUCHER: '/cards/bitsa/voucher/redeem-voucher',
  REQUEST_ACCESS: '/websecurity/request-access',
  SEND_TO_CARD: '/cards/bitsa/send-money/send-tocard',
  SEND_TO_IBAN: '/cards/bitsa/manage/send-to-iban',
  SEND_TO_PHONE: '/cards/bitsa/send-money/send-tophone',
  SET_CONFIGURATION: '/account/set-configuration',
  UNLOCK_CARD: '/cards/unlock-card',
  VALIDATE_IBAN_TRANSFER: '/cards/bitsa/manage/validate-iban-transfer',
};

const LOGIN_TIME_EXPIRATION = 181000;

const SESSION_EXPIRATION_TIME = 300000;

const CANCEL_LOG_OUT_TIME = 30000;

const DELAY_DECOUPLED_REQUEST = 8000;

const screens = {
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  DETAIL: '/detail/card',
  LOGIN: '/login',
  PLANS: '/plans',
  POINTS: '/bitsa-points',
};

const i18nCardTypes = {
  BitsaCard_Plastic: 'dashboard:cards.plastic',
  BitsaCard_Virtual: 'dashboard:cards.virtual',
};

const externalLegalLink = {
  EN: 'https://www.bitsacard.com/en/legal',
  ES: 'https://www.bitsacard.com/es/legal',
  FR: 'https://www.bitsacard.com/fr/legale',
  IT: 'https://www.bitsacard.com/it/legale',
};

const externalPrivacyLink = {
  EN: 'https://www.bitsacard.com/en/privacy-policy',
  ES: 'https://www.bitsacard.com/es/politica-privacidad',
  FR: 'https://www.bitsacard.com/fr/politique-confidentialite',
  IT: 'https://www.bitsacard.com/it/politica-privacy',
};

const externalConditionsLink = {
  EN: 'https://www.bitsacard.com/en/general-conditions',
  ES: 'https://www.bitsacard.com/es/condiciones-generales',
  FR: 'https://www.bitsacard.com/fr/conditions-generales',
  IT: 'https://www.bitsacard.com/it/condizioni-generali',
};

const externalUsesLink = {
  EN: 'https://www.bitsacard.com/en/terms-of-use',
  ES: 'https://www.bitsacard.com/es/terminos-de-uso',
  FR: 'https://www.bitsacard.com/fr/conditions-de-utilisation',
  IT: 'https://www.bitsacard.com/it/condizioni-di-uso',
};

const COMMON_SWITCHERY_OPTIONS = {
  disabledOpacity: 0.25,
  secondaryColor: 'var(--cloudy-blue)',
  color: 'var(--dark)',
  jackColor: 'var(--pale-grey)',
  jackSecondaryColor: 'var(--pale-grey)',
};

const LANGUAGES_AVAILABE = ['en', 'es', 'fr', 'it'];

const cardTypes = {
  PLASTIC: 'plastic',
  VIRTUAL: 'virtual',
};

const cardSubTypes = {
  BitsaCard_Plastic: 'plastic',
  BitsaCard_Virtual: 'virtual',
};

const programTypes = {
  COMMON: 'common',
  COMMON20: 'common',
  YOUNG: 'young',
  BITSAYOUNG: 'young',
};

const cardPrograms = {
  COMMON: ['COMMON', 'COMMON20'],
  YOUNG: ['YOUNG', 'BITSAYOUNG'],
};

const cardOptions = {
  TOP_UP: 'topUp',
  SEND_MONEY: 'send',
  CHANGE_ALIAS: 'alias',
};

const cardStatus = {
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
};

const ERROR_CODE_INVALID_TOKEN = 10;

const ERROR_CODE_NOT_ENOUGHT_ACCOUNT_BALANCE = 35100;

const detailOptions = {
  TRANSACTIONS: 'transactions',
  VOUCHER: 'voucher',
  CRYPTO: 'crypto',
  TRANSFER: 'transfer',
  CARD: 'card',
  BANK_ACCOUNT: 'bank_account',
  MOBILE: 'mobile',
  BITSA: 'bitsa',
};

const amountRegex = /^\d+(\.\d{1,2})?$/;

const paymentStatus = {
  PENDING: 'Pending',
  PAID: 'Paid',
  CANCELED: 'Canceled',
};

const cryptoNames = {
  BTC: 'Bitcoin',
  LBTC: 'Bitcoin Lightning',
  BAT: 'Basic Attention Token',
  BCH: 'Bitcoin Cash',
  DASH: 'Dash',
  ETH: 'Ethereum',
  LTC: 'Litecoin',
  DAI: 'Multi-Collateral Dai',
  XMR: 'Monero',
  TRX: 'Tron',
  XLM: 'Stellar',
  XRP: 'Ripple',
  ZEC: 'ZCash',
  USDT: 'Tether',
  ONT: 'Ontology',
  ONG: 'Ontology Gas',
};

const defaultDiallingCodes = {
  EN: 'gb',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
};

const CARD_TYPES = {
  plastic: 'BitsaCard_Plastic',
  virtual: 'BitsaCard_Virtual',
};

const i18nTransactionDetailStatus = {
  APPROVED: 'approved',
  DECLINED: 'rejected',
  UNKNOWN: 'unknown',
};

const DEFAULT_LOCATION = { lat: 40.416775, lng: -3.70379 };

const DEFAULT_ZOOM = 6;

const ZOOM_WITH_GRANTED_LOCATION = 15;

const GOOGLE_MAPS_LIBRARIES = ['places'];

const DEFAULT_RADIO_KM = 10;

const bitsaPointTypes = {
  TOP_UP: 2,
  PINCASH: 1,
  ATM: 3,
};

const mapZoomLevels = {
  10: 15,
  20: 14,
  30: 13,
  40: 11,
  50: 10,
};

const mapSliderLimits = {
  MAX: 50,
  MIN: 10,
};

const mapReducerActions = {
  INITIAL_UPDATE: 'INITIAL_UPDATE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_LOCATION_AND_SEARCH: 'UPDATE_LOCATION_AND_SEARCH',
  SEARCH_POINTS: 'SEARCH_POINTS',
};

const RADIX = 10;

const PLAN_TYPES = {
  PRO: 'PRO',
  MOVE: 'MOVE',
  FREE: 'FREE',
};

const PLAN_PERIODS = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

const COLORS = {
  FREE: 'var(--pale-grey)',
  MOVE: '#F08900',
  PRO: '#153b68',
};

const PLAN_COLOR_SCHEMES = [
  {
    height: '100',
    mainColor: 'blueBitsa',
    priceColor: 'dark',
  },
  {
    height: '110',
    mainColor: 'orange',
    priceColor: 'dark',
  },
  {
    height: '100',
    mainColor: 'dark',
    priceColor: 'blueBitsa',
  },
];

const PLAN_CHANGE = 'PLAN_CHANGE';

const CONFIRM_PLAN_CHANGE = 'CONFIRM_PLAN_CHANGE';

const CONFIRM_PLAN_CHANGE_PERIOD = 'CONFIRM_PLAN_CHANGE_PERIOD';

const CONFIRM_PLAN_CANCEL_RENEW = 'CONFIRM_PLAN_CANCEL_RENEW';

export {
  amountRegex,
  bitsaPointTypes,
  CANCEL_LOG_OUT_TIME,
  cardOptions,
  cardPrograms,
  cardStatus,
  cardSubTypes,
  cardTypes,
  CARD_TYPES,
  COLORS,
  COMMON_SWITCHERY_OPTIONS,
  CONFIRM_PLAN_CANCEL_RENEW,
  CONFIRM_PLAN_CHANGE,
  CONFIRM_PLAN_CHANGE_PERIOD,
  cryptoNames,
  DEFAULT_LOCATION,
  defaultDiallingCodes,
  DEFAULT_RADIO_KM,
  DEFAULT_ZOOM,
  DELAY_DECOUPLED_REQUEST,
  detailOptions,
  ERROR_CODE_INVALID_TOKEN,
  ERROR_CODE_NOT_ENOUGHT_ACCOUNT_BALANCE,
  externalApiPaths,
  externalConditionsLink,
  externalLegalLink,
  externalPrivacyLink,
  externalUsesLink,
  GOOGLE_MAPS_LIBRARIES,
  internalApiPaths,
  i18nCardTypes,
  i18nTransactionDetailStatus,
  LANGUAGES_AVAILABE,
  LOGIN_TIME_EXPIRATION,
  mapReducerActions,
  mapSliderLimits,
  mapZoomLevels,
  paymentStatus,
  PLAN_CHANGE,
  PLAN_COLOR_SCHEMES,
  PLAN_PERIODS,
  PLAN_TYPES,
  programTypes,
  RADIX,
  screens,
  SESSION_EXPIRATION_TIME,
  ZOOM_WITH_GRANTED_LOCATION,
};
