import Router from 'next/router';
import { i18n } from '@/i18n';
import { ERROR_CODE_INVALID_TOKEN, SESSION_EXPIRATION_TIME } from '@/constants';
import { showErrorSwal, getSessionExpiredSwal } from './swal';
import { removeToken } from './cookies';

let timeout = 0;

const logOut = () => {
  removeToken();
  Router.push('/login');
};

const manageSession = () => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    getSessionExpiredSwal().then(({ dismiss }) => {
      if (dismiss === 'cancel' || dismiss === 'timer') {
        logOut();
        return;
      }
      manageSession();
    });
  }, SESSION_EXPIRATION_TIME);
};

const getHeaders = (ip, token) => {
  return {
    'Content-Type': 'application/json',
    'x-bitnovo-h-appid': `${process.env.API_HEADER_APPID_FIRST}:${process.env.API_HEADER_APPID_SECOND}`,
    'x-bitnovo-h-appversion': process.env.APP_VERSION,
    'x-bitnovo-h-language-iso2': i18n.language || 'es',
    'X-Forwarded-For': ip,
    ...(token && {
      Authorization: `Bearer ${token}`,
    }),
  };
};

const getProperties = (ip, token = null, method = 'POST') => ({
  method,
  mode: 'cors',
  headers: getHeaders(ip, token),
});

const handleJSONResponse = r => r.json();

const handleData = async response => {
  const data = await response.json();
  if (!data.hasError) {
    return data;
  }

  if (data.error.code === ERROR_CODE_INVALID_TOKEN) {
    clearTimeout(timeout);
    showErrorSwal(i18n.t('error.invalidToken')).then(() => {
      logOut();
    });
  } else if (data.error.showToCustomer) {
    showErrorSwal(data.error.customerDescription);
  }

  const error = { code: data.error.code, privateDescription: data.error.privateDescription };
  return Promise.resolve({ hasError: true, error });
};

const handleResponse = response => {
  if (process.env.SHOW_TOKEN !== '1' && !/websecurity/.test(response.url)) {
    manageSession();
  }

  if (response.ok) {
    return handleData(response);
  }
  const error = { code: response.status, privateDescription: response.statusText };
  return Promise.resolve({ hasError: true, error });
};

const fetcher = async (path, method, token, body) =>
  fetch(path, {
    method,
    ...(token && { headers: { authorization: `Bearer ${token}` } }),
    ...(method !== 'GET' && body && { body: JSON.stringify(body) }),
  }).then(handleResponse);

const swrFetcher = (...params) => {
  const path = params.shift();
  const method = params.shift();
  const token = params.shift();

  return fetcher(path, method, token, params);
};

const mutateFetcher = (...params) => {
  params.shift(); // key
  const path = params.shift();
  const method = params.shift();
  const token = params.shift();

  return fetcher(path, method, token, params);
};

export { fetcher, getProperties, handleJSONResponse, mutateFetcher, swrFetcher };
