import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { i18n } from '@/i18n';
import {
  LOGIN_TIME_EXPIRATION,
  CANCEL_LOG_OUT_TIME,
  detailOptions,
  ERROR_CODE_NOT_ENOUGHT_ACCOUNT_BALANCE,
} from '@/constants';
import Lottie from 'react-lottie';
import getLottieOptions from '@/animations/utils';
import { formatMinutes } from './dates';

const BitsaSwal = withReactContent(Swal);

const commonOptions = {
  showCloseButton: true,
  allowEscapeKey: false,
  allowOutsideClick: false,
  reverseButtons: true,
  buttonsStyling: false,
  scrollbarPadding: false,
  customClass: {
    image: 'bitsa-swal-image',
    title: 'bitsa-swal-title',
    confirmButton: 'bitsa-swal-button bitsa-button',
    cancelButton: 'bitsa-swal-button bitsa-button cancel',
  },
  showClass: {
    popup: 'animated fadeInDown faster',
  },
  hideClass: {
    popup: 'animated fadeOutUp faster',
  },
};

const levelsOptions = (showConfirmButton = false) => ({
  ...commonOptions,
  customClass: {
    content: 'levels-swal-content',
    confirmButton: 'bitsa-levels-button bitsa-button',
  },
  showConfirmButton,
});

const showLevelsSwal = error => {
  if (!error) {
    return BitsaSwal.fire({
      ...levelsOptions(),
      html: (
        <>
          <div className="lottie-container">
            <Lottie options={getLottieOptions('statusOk')} width="70%" />
          </div>
          <h4>{i18n.t('swal:levels.success.title')}</h4>
          <p>{i18n.t('swal:levels.success.description')}</p>
        </>
      ),
    });
  }
  if (error.code === ERROR_CODE_NOT_ENOUGHT_ACCOUNT_BALANCE) {
    return BitsaSwal.fire({
      // TODO: show top up modal
      ...levelsOptions(),
      html: (
        <>
          <img src="/img/levels/error.svg" alt="error" />
          <h4 className="error">{i18n.t('swal:levels.errorBalance.title')}</h4>
          <p>{i18n.t('swal:levels.errorBalance.description')}</p>
        </>
      ),
    });
  }
  return BitsaSwal.fire({
    ...levelsOptions(),
    html: (
      <>
        <img src="/img/levels/error.svg" alt="error" />
        <h4 className="error">{i18n.t('swal:levels.error.title')}</h4>
        <p>{i18n.t('swal:levels.error.description')}</p>
      </>
    ),
  });
};

const getLoginSwal = promise => {
  let timerInterval = '';
  return BitsaSwal.fire({
    ...commonOptions,
    icon: 'info',
    title: ' ',
    html: `${i18n.t('swal:login.text1')}<br /><br />${i18n.t('swal:login.text2')}<br />${i18n.t('swal:login.text3')}`,
    customClass: {
      confirmButton: 'bitsa-swal-button bitsa-button cancel',
    },
    confirmButtonText: i18n.t('common:button.cancel'),
    timer: LOGIN_TIME_EXPIRATION,
    onBeforeOpen: () => {
      timerInterval = setInterval(() => {
        const title = Swal.getTitle();
        if (title) {
          const timer = Swal.getTimerLeft();
          title.textContent = formatMinutes(timer < 100 ? 0 : timer);
        }
      }, 100);
    },
    onClose: () => {
      clearInterval(timerInterval);
      promise.cancel();
    },
  });
};

const getSessionExpiredSwal = () => {
  let timerInterval = '';
  return BitsaSwal.fire({
    ...commonOptions,
    customClass: {
      ...commonOptions.customClass,
      title: 'bitsa-swal-title mt-0',
    },
    icon: 'info',
    title: ' ',
    html:
      '<div class="bitsa-swal-session-expired">' +
      `${i18n.t('swal:sessionExpired.text1')}` +
      '<br />' +
      `<span>${i18n.t('swal:sessionExpired.text2')}</span>` +
      '<br /><br />',
    showCancelButton: true,
    confirmButtonText: i18n.t('swal:sessionExpired.confirm'),
    cancelButtonText: i18n.t('swal:sessionExpired.cancel'),
    timer: CANCEL_LOG_OUT_TIME,
    onBeforeOpen: () => {
      timerInterval = setInterval(() => {
        const title = Swal.getTitle();
        if (title) {
          const timer = Swal.getTimerLeft();
          title.textContent = formatMinutes(timer < 100 ? 0 : timer);
        }
      }, 100);
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  });
};

const showErrorSwal = text =>
  BitsaSwal.fire({
    ...commonOptions,
    imageUrl: '/img/swal/error.svg',
    text,
    confirmButtonText: i18n.t('common:button.accept'),
  });

const showInfoSwal = (image, title, text) =>
  BitsaSwal.fire({
    ...commonOptions,
    imageUrl: `/img/swal/${image}.svg`,
    ...(title && { title: i18n.t(title) }),
    html: i18n.t(text),
    confirmButtonText: i18n.t('common:button.accept'),
  });

const showConfirmSwal = (imageUrl, text) =>
  BitsaSwal.fire({
    ...commonOptions,
    imageUrl,
    text: i18n.t(text),
    showCancelButton: true,
    confirmButtonText: i18n.t('common:button.accept'),
    cancelButtonText: i18n.t('common:button.cancel'),
  });

const showInputSwal = (title, inputValue) =>
  BitsaSwal.fire({
    ...commonOptions,
    title: i18n.t(title),
    input: 'text',
    inputValue,
    showCancelButton: true,
    confirmButtonText: i18n.t('common:button.accept'),
    cancelButtonText: i18n.t('common:button.cancel'),
    inputValidator: value => value.length > 25 && i18n.t('swal:alias.error'),
  });

const showTopUpSelectionSwal = () => {
  let value = '';
  const setValue = ({ currentTarget }) => {
    value = currentTarget.id;
    BitsaSwal.clickConfirm();
  };

  return BitsaSwal.fire({
    ...commonOptions,
    title: i18n.t('detail:topUp.selection'),
    html:
      '<div class="bitsa-swal-selection">' +
      `<div id=${detailOptions.VOUCHER}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/voucher.svg" />' +
      `${i18n.t('detail:topUp.selection.voucher')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      `<div id=${detailOptions.CRYPTO}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/crypto.svg" />' +
      `${i18n.t('detail:topUp.selection.crypto')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      `<div id=${detailOptions.TRANSFER}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/transfer.svg" />' +
      `${i18n.t('detail:topUp.selection.transfer')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      `<div id=${detailOptions.CARD}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/card.svg" />' +
      `${i18n.t('detail:topUp.selection.card')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      '</div>',
    showConfirmButton: false,
    onOpen: () => {
      document.getElementById(detailOptions.VOUCHER).addEventListener('click', setValue);
      document.getElementById(detailOptions.CRYPTO).addEventListener('click', setValue);
      document.getElementById(detailOptions.TRANSFER).addEventListener('click', setValue);
      document.getElementById(detailOptions.CARD).addEventListener('click', setValue);
    },
    preConfirm: () => value,
  });
};

const showTopUpCryptoSwal = (amount, crypto) =>
  BitsaSwal.fire({
    ...commonOptions,
    title: i18n.t('detail:topUp.crypto.confirmation.title'),
    html:
      '<div class="bitsa-swal-topUp-crypto">' +
      `<p>${i18n.t('detail:topUp.crypto.confirmation.text1')}</p>` +
      `<span>${amount} ${crypto}.</span><br /><br />` +
      `<p>${i18n.t('detail:topUp.crypto.confirmation.text2')}</p>` +
      '</div>',
    showCancelButton: true,
    confirmButtonText: i18n.t('detail:topUp.crypto.confirmation.confirm'),
    cancelButtonText: i18n.t('detail:topUp.crypto.confirmation.cancel'),
  });

const showSendSelectionSwal = () => {
  let value = '';
  const setValue = ({ currentTarget }) => {
    value = currentTarget.id;
    BitsaSwal.clickConfirm();
  };

  return BitsaSwal.fire({
    ...commonOptions,
    title: i18n.t('detail:send.selection'),
    html:
      '<div class="bitsa-swal-selection">' +
      `<div id=${detailOptions.BANK_ACCOUNT}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/transfer.svg" />' +
      `${i18n.t('detail:send.selection.transfer')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      `<div id=${detailOptions.MOBILE}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/mobile.svg" />' +
      `${i18n.t('detail:send.selection.mobile')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      `<div id=${detailOptions.BITSA}>` +
      '<span>' +
      '<img alt="" class="mr-3" src="/img/detail/icons/bitsa.svg" />' +
      `${i18n.t('detail:send.selection.bitsa')}` +
      '</span>' +
      '<i class="fas fa-chevron-right"></i>' +
      '</div>' +
      '</div>',
    showConfirmButton: false,
    onOpen: () => {
      document.getElementById(detailOptions.BANK_ACCOUNT).addEventListener('click', setValue);
      document.getElementById(detailOptions.MOBILE).addEventListener('click', setValue);
      document.getElementById(detailOptions.BITSA).addEventListener('click', setValue);
    },
    preConfirm: () => value,
  });
};

const showTransactionDetailSwal = ({
  icon,
  description,
  amountFormatted,
  id,
  operationDate,
  chargeDate,
  tarjeta,
  descComercio,
  provComercio,
  i18nStatus,
}) =>
  BitsaSwal.fire({
    ...commonOptions,
    customClass: {
      image: 'bitsa-swal-transaction-detail-image',
    },
    imageUrl: icon,
    html:
      '<div class="bitsa-transaction-detail">' +
      '<div class="bitsa-transaction-detail__header">' +
      '<span class="title">' +
      `${description}` +
      '</span>' +
      '<span class="amount">' +
      `${amountFormatted}` +
      '</span>' +
      '<span class="id">' +
      `ID: ${id}` +
      '</span>' +
      '</div>' +
      '<div class="bitsa-transaction-detail__items">' +
      '<div class="item">' +
      '<span class="label">' +
      `${i18n.t('transactions:detail.operationDate.date')}` +
      '</span>' +
      '<span class="value">' +
      `${operationDate.date}` +
      '</span>' +
      '</div>' +
      '<div class="item">' +
      '<span class="label">' +
      `${i18n.t('transactions:detail.operationDate.time')}` +
      '</span>' +
      '<span class="value">' +
      `${operationDate.time}` +
      '</span>' +
      '</div>' +
      '<div class="item">' +
      '<span class="label">' +
      `${i18n.t('transactions:detail.chargeDate.date')}` +
      '</span>' +
      '<span class="value">' +
      `${chargeDate.date}` +
      '</span>' +
      '</div>' +
      '<div class="item">' +
      '<span class="label">' +
      `${i18n.t('transactions:detail.chargeDate.time')}` +
      '</span>' +
      '<span class="value">' +
      `${chargeDate.time}` +
      '</span>' +
      '</div>' +
      `${(tarjeta &&
        '<div class="item">' +
          '<span class="label">' +
          `${i18n.t('transactions:detail.card')}` +
          '</span>' +
          '<span class="value">' +
          `${tarjeta}` +
          '</span>' +
          '</div>') ||
        ''}` +
      `${(descComercio &&
        '<div class="item">' +
          '<span class="label">' +
          `${i18n.t('transactions:detail.descComercio')}` +
          '</span>' +
          '<span class="value">' +
          `${descComercio}` +
          '</span>' +
          '</div>') ||
        ''}` +
      `${(provComercio &&
        '<div class="item">' +
          '<span class="label">' +
          `${i18n.t('transactions:detail.provComercio')}` +
          '</span>' +
          '<span class="value">' +
          `${provComercio}` +
          '</span>' +
          '</div>') ||
        ''}` +
      '<div class="item">' +
      '<span class="label">' +
      `${i18n.t('transactions:detail.status.label')}` +
      '</span>' +
      '<span class="value">' +
      `${i18n.t(`transactions:detail.status.${i18nStatus}`)}` +
      '</div>' +
      '</div>' +
      '<div class="bitsa-transaction-detail__footer">' +
      '<span>' +
      `${i18n.t('transactions:detail.footer.text1')}` +
      '</span>' +
      '<span>' +
      `${i18n.t('transactions:detail.footer.text2')}` +
      '</span>' +
      '</div>' +
      '</div>',
    showConfirmButton: false,
  });

export {
  getLoginSwal,
  getSessionExpiredSwal,
  showConfirmSwal,
  showErrorSwal,
  showInfoSwal,
  showInputSwal,
  showLevelsSwal,
  showSendSelectionSwal,
  showTopUpCryptoSwal,
  showTopUpSelectionSwal,
  showTransactionDetailSwal,
};
