import nextCookies from 'next-cookies';
import Cookies from 'js-cookie';
import { DEFAULT_LOCATION } from '@/constants';

const getLanguage = ctx => nextCookies(ctx)['next-i18next'] || 'en';

const getToken = ctx => nextCookies(ctx)['access-token'] || '';

const setToken = token => Cookies.set('access-token', token);

const removeToken = () => Cookies.remove('access-token');

const getLocation = ctx => nextCookies(ctx).location || DEFAULT_LOCATION;

const setLocation = coords => Cookies.set('location', coords);

export { getLanguage, getLocation, getToken, removeToken, setLocation, setToken };
